import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Toast from "react-bootstrap/Toast";
import { resetToast } from "../../../redux/userSlice";
import ToastContainer from "react-bootstrap/ToastContainer";

const ToastComponent = () => {
  const { toastMsg } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  //   const types = ['error', 'info', 'success', 'warning'];

  const onHiding = () => dispatch(resetToast());

  return (
    <>
      <ToastContainer className="p-3" position="bottom-center">
        <Toast
          onClose={onHiding}
          show={toastMsg ? true : false}
          delay={5000}
          bg={toastMsg ? (toastMsg?.type ? toastMsg.type : "error") : ""}
          autohide
        >
          <Toast.Header>
            <strong className="me-auto">{toastMsg ? toastMsg.msg : ""}</strong>
          </Toast.Header>
        </Toast>
      </ToastContainer>
    </>
  );
};

export default ToastComponent;
