import { createSlice } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { LocalStorage } from "../services/LocalStorage.service";

const initialState = {
    user: null,
    orgDetails: null,
    token: null,
    refresh: null,
    status: "idle",
    videoWatched: false,
    electionDetails: null,
    contacts: { count: 0, results: [], page: 1 },
    toastMsg: null,
    challengeList: null,
    referralData: null,
    orgRedemptionOptions: [],
    currentChallenge: null
};

const persistConfig = {
    key: "block-power",
    storage,
    whitelist: [
        "user",
        "orgDetails",
        "phone",
        "refresh",
        "videoWatched",
        "electionDetails",
        "challengeList",
        "currentChallenge"
    ],
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        signIn: (state, action) => {
            const { access, phone, refresh } = action.payload;
            state.token = access;
            state.refresh = refresh ? refresh : state?.refresh;
            state.phone = phone;
            state.user = {
                ...action.payload,
                first_name: capitalizeFirstLetter(action.payload.first_name),
                last_name: capitalizeFirstLetter(action.payload.last_name),
            };
            const d = new Date();
            d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
            document.cookie = `persistent_auth=${access}; ${window.location.host.includes("localhost") || window.location.host.includes("192.168.0.110:3000") ? "" : "domain=." + window.location.host.replace("www.", "") + "; "} expires=${d.toUTCString()}; path=/;`;
        },
        refreshAccessToken: (state, action) => {
            const { access } = action.payload;
            state.token = action.payload;
            const d = new Date();
            d.setTime(d.getTime() + (7 * 24 * 60 * 60 * 1000));
            document.cookie = `persistent_auth=${access}; ${window.location.host.includes("localhost") || window.location.host.includes("192.168.0.110:3000") ? "" : "domain=." + window.location.host.replace("www.", "") + "; "} expires=${d.toUTCString()}; path=/;`;
        },
        signOut: (state) => {
            Object.assign(state, { ...initialState, orgDetails: { ...state.orgDetails } });
            document.cookie = `persistent_auth=; ${window.location.host.includes("localhost") || window.location.host.includes("192.168.0.110:3000") ? "" : "domain=." + window.location.host.replace("www.", "") + "; "} expires=${new Date().toUTCString()}; path=/;`;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        },
        setOrgDetails: (state, action) => {
            state.orgDetails = action.payload;
            LocalStorage.set(
                "login_image",
                action.payload.login_image
            );
        },
        setContacts: (state, action) => {
            state.contacts.count = action.payload?.count;
            state.contacts.results = [...state.contacts.results, ...action.payload?.results];
            state.contacts.page = action.payload?.page;
        },
        replaceContacts: (state, action) => {
            state.contacts.count = action.payload?.count;
            state.contacts.results = action.payload?.results;
            state.contacts.page = action.payload?.page;
        },
        setContacted: (state, action) => {
            state.contacts = {
                ...state.contacts,
                uncontacted: state.contacts.uncontacted.filter(ele => ele.contact_id !== action.payload.contact_id),
                pending: [...state.contacts.pending, { ...action.payload, status: "CONTACTED" }]
            };
        },
        updateVideoWatchStatus: (state) => {
            state.videoWatched = true;
        },
        setToast: (state, action) => {
            state.toastMsg = action.payload
        },
        resetToast: (state) => {
            state.toastMsg = null
        },
        updateChallenge: (state, action) => {
            state.challengeList = action.payload
        },
        setReferralData: (state, action) => {
            state.referralData = action.payload
        },
        getOrgRedemptionOptions: (state, action) => {
            state.orgRedemptionOptions = action.payload
        },
        updatePointsInfo: (state, action) => {
            state.user = { ...state.user, points_breakdown: action.payload }
        },
        updateElectionDetails: (state, action) => {
            state.electionDetails = action.payload
        },
        updateCurrentChallenge: (state, action) => {
            state.currentChallenge = action.payload
        },
    }
});

export const {
    signIn,
    refreshAccessToken,
    signOut,
    setUser,
    setOrgDetails,
    setContacts,
    replaceContacts,
    setContacted,
    updateVideoWatchStatus,
    setToast,
    resetToast,
    updateChallenge,
    setReferralData,
    getOrgRedemptionOptions,
    updatePointsInfo,
    updateElectionDetails,
    updateCurrentChallenge
} = userSlice.actions;

export const userData = (state) => state;

export default persistReducer(persistConfig, userSlice.reducer);


const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
};