export const UNAUTHORIZED_STATUS = 401;
export const NOT_FOUND = 404;
export const INTERNAL_SERVER_ERROR = 500;
export const DURHAM_DELIVERS = 34;
export const RESEND_SESSION_TIME = 59;
export const USER_TYPE_CHOICES = {
    "SUPER_ADMIN": 1,
    "ORG_ADMIN": 2,
    "AMBASSADOR": 3,
    "FRIEND": 4,
    "CONNECTION": 5
};
export const USER_TYPE_CHOICE_LABEL = [
    { id: 1, label: "Super Admin" },
    { id: 2, label: "Org Admin" },
    { id: 3, label: "Ambassador" },
    { id: 4, label: "Friend" },
    { id: 5, label: "Connection" }
]

export const USER_STATUS_CHOICES = {
    "ACTIVE": 0,
    "ADMIN_PRE_APPROVED": 1,
    "ADMIN_APPROVAL_PENDING": 2
}

export const USER_CHALLENGE_STATUS_CHOICES = {
    "NOT_ACCEPTED": -1,
    "ACCEPTED": 0,
    "ADMIN_PENDING": 1,
    "COMPLETED": 2
};

export const USER_TYPE_CHOICE_LABEL_VALUE = [
    { id: -1, label: "Not Accepted" },
    { id: 0, label: "Accepted" },
    { id: 1, label: "Admin Pending" },
    { id: 2, label: "Completed" },
]

export const CHALLENGE_UNIQUE_ID = {
    "Home_Energy_Efficiency": "HEE1000",
    "Library_Card_Challenge": "LCC50",
    "Voting_Plan_Challenge": "VPC1000",
    "Test_new_challenge": "TNC100",
    "Attendance_Challenge": "EVENT100",
    "Register_To_Vote": "VRC1000"
}

export const TXN_TYPE_CHOICES = {
    "EARNED": 0,
    "REDEEMED": 1
}

export const REDEEM_TYPE = {
    "ORG_DONATE": 0,
    "TREE": 1,
    "MERCHANDISE": 2,
    "TANGO": 3,
    "RAMP": 4
}

export const CONTACT_STATUS_ENUM = {
    "NOT_ADDED": 1,
    "UN_CONTACTED": 2,
    "LINKED_COPIED": 3,
    "PENDING": 4,
    "ADMIN_PENDING": 5,
    "COMPLETED": 6
}